.navigation {
  display: none;
}

@media (min-width: $tablet) {
  .navigation {
    position: fixed;
    user-select: none;
    top: 0;
    bottom: 0;
    left: 1.75rem;
    z-index: 50;
    width: 1rem;
    margin: auto;
    margin-right: 1.75rem;
    display: table;
    height: 100%;
  }

  .navigation-links {
    list-style: none;
    display: table-cell;
    vertical-align: middle;
    user-select: none;
    padding: 0;
    margin: 0;
  }

  .navigation-item {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    position: relative;
    margin: 0.75rem 0;

    & a {
      opacity: 0;
      white-space: nowrap;
      transform: translate3d(-5px, 0px, 0px);
      transition: transform 150ms ease-out, opacity 150ms ease-out;
      position: absolute;
      top: 0;
      left: -0.375rem;
      text-transform: capitalize;
      line-height: 0.8em;
      color: #fff;
      background: palette($primary);
      padding: 0.4rem 1rem 0.4rem 1.75rem;
      margin-top: -0.25rem;
      border-radius: 1.25rem;
    }

    &:hover a {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }

    &::after {
      content: '';
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      border: 2px solid palette(gray);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transition: all 150ms ease-out;
      background-color: $white;
    }

    &:hover::after {
      background: transparent;
      border-color: #fff;
    }
  }
}
