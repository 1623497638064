label {
  display: block;
  margin-top: 1.25em;
  font-family: $sans;
}

input,
textarea {
  padding: 0.4em 0.8em;
  border: 1px solid palette(gray);
  background-color: palette(gray, lighter);
  font-family: $sans;
  line-height: 1.5em;
  outline: none;
  margin: 0.3em 0;
  width: 100%;
  text-align: left;
  width: 100%;
  resize: none;
  transition: border 0.15s ease-in-out;
  &:focus,
  &:active {
    border: 1px solid palette($primary);
  }
}

textarea {
  resize: vertical;
}

input[type='submit'].button,
button.button {
  display: block;
  outline: none;
  font-size: 0.83rem;
  font-family: $sans;
  font-weight: $sans-bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  padding: 0.75rem 1.75rem;
  border: 3px solid palette($primary);
  color: palette(gray, darker);
  background-color: #fff;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: palette($primary);
    cursor: pointer;
  }
}

input[type='submit'].button-text,
button.button-text,
a.button-text {
  display: block;
  outline: none;
  font-family: $sans;
  font-size: 1.25rem;
  letter-spacing: -0.003em;
  text-transform: capitalize;
  border: none;
  text-decoration: none;
  color: palette($primary);
  background-color: #fff;
  cursor: pointer;
}
