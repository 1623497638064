.post {
  padding-bottom: 4rem;
  padding-top: 5rem;
  position: relative;
  border-bottom: 1px solid palette(gray, lighter);
  height: 100%;
}

.post-header {
  margin-bottom: 1.5rem;
  text-align: center;
  font-family: $display;
}

.post-title {
  text-align: center;
  font-family: $display;
  margin-bottom: 4rem;

  &::after {
    content: '';
    height: 0.4rem;
    width: 7rem;
    background-color: palette(gray, lighter);
    display: block;
    margin: 1.75rem auto;
    border-radius: 0.2rem;
  }

  a::before {
    content: none !important;
  }
}

.post-image {
  height: 61.8vw;
  width: 100%;
  object-fit: cover;
  margin-bottom: 1rem;
}

@media (min-width: $tablet) {
  .post {
    &.-fixed .post-image {
      position: fixed;
      bottom: 0;
    }
    &.-absolute-top .post-image {
      position: absolute;
      top: 0;
    }
    &.-absolute-bottom .post-image {
      position: absolute;
      bottom: 0;
    }
    &.-first .post-image {
      height: 100vh;
    }
  }

  .post-image {
    max-height: 100vh;
    height: inherit;
    width: 50vw;
    left: 50vw;
    margin-bottom: 0;
  }
}

// Typography

.post a,
a.link {
  position: relative;
  display: inline-block;
  color: palette(gray, darker);
  transition: color 0.15s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: palette($primary);
    transform: translate(0px, -5px);
  }

  &:hover,
  &:focus,
  &:active {
    color: palette($primary);
  }

  strong {
    color: inherit;
  }
}

blockquote {
  color: palette($primary);
  padding: 2rem 0;
  margin: 0;
}

blockquote > p {
  font-family: $display;
  font-size: 1.5rem;
  line-height: 1.5;
  padding-left: 0.75em;
  border-left: 3px solid palette(gray, light);
}
